import React, { Component } from 'react'
import * as d3 from 'd3'
// import * as _ from 'lodash'

class BarChart extends Component {
  constructor(props) {
    super(props)

    this.margin = { top: 10, right: 25, bottom: 50, left: 50 }
    this.yScale = null
    this.xScale = null
  }

  componentDidMount() {
    this.setupChart()
    this.drawData()
  }

  componentDidUpdate() {
    this.drawData()
  }

  setupChart() {
    // https://blog.risingstack.com/d3-js-tutorial-bar-charts-with-javascript/

    const w = this.props.width - this.margin.left - this.margin.right
    const h = this.props.height - this.margin.top - this.margin.bottom

    // create svg object in body
    const svg = d3
      .select(`#${this.props.id}`)
      .append('svg')
      .attr('width', this.props.width)
      .attr('height', this.props.height)

    // add a group for axis
    svg
      .append('g')
      .attr('class', 'axes')
      .attr('transform', `translate(${this.margin.left},${this.margin.top})`)

    // yScale and axis
    this.yScale = d3
      .scaleLinear()
      .range([h, 0])
      .domain([0, 300])
    svg
      .select('g.axes')
      .append('g')
      .attr('class', 'axis yAxis')
      .call(d3.axisLeft(this.yScale))

    // xScale and axis
    this.xScale = d3
      .scaleBand()
      .range([0, w])
      .padding(0.2)
    svg
      .select('g.axes')
      .append('g')
      .attr('class', 'axis xAxis')
      .attr('transform', `translate(0,${h})`)
      .call(d3.axisBottom(this.xScale))

    // Axis Labels
    svg
      .append('g')
      .attr('class', 'axislabels')
      .attr('transform', `translate(${this.margin.left},${this.margin.top})`)

    // AXIS LABELS
    // y axis
    svg
      .select('g.axislabels')
      .append('text')
      .text('Verbindungen')
      .attr('text-anchor', 'middle')
      .attr('x', -(h / 2) - this.margin.top)
      .attr('y', -40)
      .attr('transform', `rotate(-90)`)
    // x axis label
    svg
      .select('g.axislabels')
      .append('text')
      .text('Stadt')
      .attr('text-anchor', 'middle')
      .attr('x', w / 2)
      .attr('y', h + 40)

    // group for data
    svg
      .append('g')
      .attr('class', 'chart')
      .attr(
        'transform',
        `translate(${this.margin.left + 1},${this.margin.top})`
      )
  }

  drawData() {
    // console.log(`%cdrawData() in BarChart.js`, 'background-color: Aquamarine;')

    const data = this.props.data
    const h = this.props.height - this.margin.top - this.margin.bottom

    const svg = d3.select(`#${this.props.id} svg`)

    // update xScale with the # of data objects
    this.xScale.domain(
      data.map(function(d) {
        return d.id
      })
    )
    // this.yScale.domain([
    //   0,
    //   d3.max(data, d => {
    //     return d.local
    //   }),
    // ])
    // this.yScale.domain([0, 300])

    // update axis with new data
    // left axis
    svg
      .select('.yAxis')
      .transition()
      .duration(300)
      .call(d3.axisLeft(this.yScale))
    // bottom axis
    svg
      .select('.xAxis')
      .call(d3.axisBottom(this.xScale))
      .selectAll('text')
      .style('text-anchor', 'middle')

    // re-join data
    const bars = svg
      .select('.chart')
      .selectAll('rect')
      .data(this.props.data)

    // remove unneded bars with animation
    bars
      .exit()
      // .transition()
      // .duration(500)
      // .style('opacity', 0)
      .remove()

    // add bars where necessary (create bars with 0 height at their final position)
    bars
      .enter()
      .append('rect')
      .attr('x', (d, i) => {
        // return i * 70
        return this.xScale(d.id)
      })
      .attr('y', (d, i) => {
        return this.yScale(d.local)
      })
      .attr('height', (d, i) => {
        return h - this.yScale(d.local)
      })
      .attr('width', this.xScale.bandwidth())
      .attr('fill', 'orange')

    // update all bars attributes
    bars
      .transition()
      .duration(500)
      .attr('x', (d, i) => {
        // return i * 70
        return this.xScale(d.id)
      })
      .attr('y', (d, i) => {
        return this.yScale(d.local)
      })
      .attr('height', (d, i) => {
        return h - this.yScale(d.local)
      })
      .attr('width', this.xScale.bandwidth())

    // add labels
    const labels = svg
      .select('.chart')
      .selectAll('text')
      .data(this.props.data)

    // remove unnecessary labels
    labels
      .exit()
      .transition()
      .duration(500)
      .style('opacity', 0)
      .remove()

    // add labels where necessary
    labels
      .enter()
      .append('text')
      .attr('class', 'value centered inverted')
      .text(d => {
        return d.local
      })
      .attr('x', (d, i) => {
        return this.xScale(d.id) + this.xScale.bandwidth() / 2
      })
      .attr('y', (d, i) => {
        return this.yScale(d.local) + 20
      })

    // update all label's positions
    labels
      .transition()
      .duration(500)
      .text(d => {
        return d.local
      })
      .attr('x', (d, i) => {
        return this.xScale(d.id) + this.xScale.bandwidth() / 2
      })
      .attr('y', (d, i) => {
        return this.yScale(d.local) + 20
      })
  }

  render() {
    return <div id={`${this.props.id}`} />
  }
}

export default BarChart
