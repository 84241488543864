class Helpers {
  //
  // RANDOMIZERS
  //

  // random full integer
  randomNumber(min, max) {
    return Math.round(Math.random() * (max - min) + min)
  }

  // random HEX color
  randomColor() {
    const letters = '0123456789ABCDEF'
    let color = '#'
    for (let index = 0; index < 6; index++) {
      // const element = letters[index];
      color += letters[Math.floor(Math.random() * 16)]
    }
    return color
  }
}

export default new Helpers()
