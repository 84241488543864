import React, { Component } from 'react'

export default class RemoveButton extends Component {
  clickHandler(index) {
    console.log('clicked on button')
    // when the prop sent into the component is a function, call it back
    // with information from the clicked element
    if (typeof this.props.onRemove === 'function') {
      // parent function is expecting an index to be removed from state
      this.props.onRemove(index)
    }
  }

  render() {
    return (
      <button
        className="removeIcon"
        style={{
          backgroundColor: this.props.colorOfButton,
        }}
        onClick={this.clickHandler.bind(this, this.props.index)}
      >
        ×
      </button>
    )
  }
}
