import React, { Component } from 'react'
import classNames from 'classnames'

import styles from '../static/css/TestLists.module.css'

import Utils from '../utils/Utils'
import ListUL from '../List'

// MaterialUI
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import IconButton from '@material-ui/core/IconButton'

// Icons
import Delete from '@material-ui/icons/Delete'

// styling and theming
import { withStyles } from '@material-ui/core/styles'

class TestLists extends Component {
  constructor(props) {
    super(props)

    this.state = {
      // for list item
      term: '',
      colorOfDelete: Utils.randomColor(),
      listItems: ['one', 'two', 'three'],
      // for fitness app tutorial
      exercises: [
        { id: 1, nameOfExercise: 'Winter' },
        { id: 2, nameOfExercise: 'Spring' },
        { id: 3, nameOfExercise: 'Summer' },
        { id: 4, nameOfExercise: 'Fall' },
      ],
      nameOfExercise: '',
    }
  }

  addData() {
    console.log('adding an entry to the list')
    const newList = this.state.listItems.concat(Utils.randomNumber(100, 500))
    this.setState(
      {
        listItems: newList,
        // colorOfDelete: Utils.randomColor(),
      },
      () => {
        console.log(`%cState has updated:`, 'background-color: Aquamarine;')
        console.log(this.state)
      }
    )
  }

  // when changing text field, update state as well
  // the term is added to the state so re-setting it to ''
  // also conveniently clears the input field!
  // also: makes it easy to read the value without selecting the HTML element!
  onChange(event) {
    this.setState({ term: event.target.value }, () => {
      // console.log(`%cState has updated:`, 'background-color: Aquamarine;')
      // console.log(this.state)
    })
  }

  addItem(event) {
    event.preventDefault()
    if (this.state.term.length <= 0) return
    // if (event.target.value == '') return
    this.setState(
      {
        term: '',
        listItems: [...this.state.listItems, this.state.term], // using spread. also works: concat. also: push() earlier then set here.
      },
      () => {
        // console.log(`%cState has updated:`, 'background-color: Aquamarine;')
        // console.log(this.state)
      }
    )
  }

  removeItem(index) {
    console.log(
      `%cRemoving item ${index} from state`,
      'background-color: Aquamarine;'
    )
    const listWithoutTheRemovedItem = this.state.listItems
      .slice(0, index)
      .concat(
        this.state.listItems.slice(index + 1, this.state.listItems.length)
      )
    this.setState({
      listItems: listWithoutTheRemovedItem,
    })
  }

  changeColorOfDeleteButton(event) {
    event.preventDefault()
    this.setState({
      colorOfDelete: Utils.randomColor(),
    })
  }

  // Control the input field (any input field, really, but setting app state according to the field's name)
  handleChange = event => {
    const { name, value } = event.target // extract name and value from event.target
    this.setState({
      [name]: value,
    })
  }

  // Submit the Excersise form
  handleCreate(event) {
    event.preventDefault()
    if (this.state.nameOfExercise) {
      this.setState(prevState => {
        return {
          exercises: [
            ...prevState.exercises,
            {
              id: Date.now(),
              nameOfExercise: prevState.nameOfExercise,
            },
          ],
          nameOfExercise: '',
        }
      })
    }
  }

  // Remove exercise from state
  handleDelete = id =>
    this.setState((state, props) => {
      return { exercises: state.exercises.filter(ex => ex.id !== id) }
    })

  render() {
    // const { classes } = this.props
    return (
      <div className={styles.highlight}>
        <Typography variant='h3' gutterBottom>
          Listentest
        </Typography>
        {
          // Excercise app from material ui tutorial
        }
        <Paper className={classNames(styles.paper, styles.box)} elevation={1}>
          <form onSubmit={this.handleCreate.bind(this)} className={styles.form}>
            <TextField
              name='nameOfExercise'
              label='Weather'
              value={this.state.nameOfExercise}
              onChange={this.handleChange.bind(this)}
              // margin="normal"
            />
            <Button type='submit' color='primary' variant='contained'>
              Create
            </Button>
          </form>
          <List>
            {
              // List all exercises
            }
            {this.state.exercises.map(item => (
              <ListItem key={item.id}>
                <ListItemText primary={item.nameOfExercise} />
                <ListItemSecondaryAction>
                  <IconButton
                    color='primary'
                    onClick={() => this.handleDelete(item.id)}
                  >
                    <Delete />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Paper>
        {/*
          A list example
          */}
        <Paper className={classNames(styles.paper, styles.box)}>
          <Typography variant='h5'>Example List</Typography>
          <Button variant='contained' onClick={this.addData.bind(this)}>
            Add Random Number
          </Button>
          <Button
            variant='contained'
            onClick={this.changeColorOfDeleteButton.bind(this)}
          >
            Change Color of Delete Button
          </Button>

          <form className='App' onSubmit={this.addItem.bind(this)}>
            <TextField
              name='textToAdd'
              label='List Item'
              value={this.state.term}
              onChange={this.onChange.bind(this)}
            />
            <Button type='submit' variant='contained' color='primary'>
              Submit
            </Button>
          </form>
          {
            // Reacting to removing an item with a callback
            // https://stackoverflow.com/questions/26176519/reactjs-call-parent-method
          }
          <ListUL
            items={this.state.listItems}
            colorOfButton={this.state.colorOfDelete}
            onRemove={this.removeItem.bind(this)}
          />
        </Paper>
      </div>
    )
  }
}

export default withStyles(styles)(TestLists)
