import React, { Component } from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
  ResponsiveContainer,
} from 'recharts'

export default class RechartsBarChart extends Component {
  render() {
    return (
      <ResponsiveContainer width="100%" height={230} className="rechart">
        <BarChart
          data={this.props.data}
          margin={{
            top: 20,
            right: 50,
            left: 10,
            bottom: 20,
          }}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#DCE35B" stopOpacity={1} />
              <stop offset="95%" stopColor="#45B649" stopOpacity={1} />
            </linearGradient>
          </defs>
          <CartesianGrid vertical={false} />
          <Tooltip />
          <Legend
            className="legendRight"
            layout="vertical"
            iconType="square"
            align="right"
          />
          <Bar dataKey="local" stackId="a" fill="rgb(253, 174, 97)">
            <LabelList dataKey="local" position="center" />
          </Bar>
          <Bar dataKey="national" stackId="a" fill="#abd9e9">
            <LabelList dataKey="national" position="center" />
          </Bar>
          <Bar dataKey="eu" stackId="a" fill="#2c7bb6">
            <LabelList dataKey="eu" position="center" />
          </Bar>
          <XAxis
            label={{ value: 'Stadt', position: 'bottom' }}
            dataKey="id"
            axisLine={false}
          />
          <YAxis
            label={{
              value: 'Verbindungen',
              angle: -90,
              position: 'left',
            }}
            domain={[0, 300]}
            axisLine={false}
          />
        </BarChart>
      </ResponsiveContainer>
    )
  }
}
