import React, { Component } from 'react'
import * as d3 from 'd3'
// import * as _ from 'lodash'

// Animate Objects entering and leaving
// https://github.com/reactjs/react-transition-group/tree/v1-stable
import { CSSTransitionGroup } from 'react-transition-group'

class ReactBarChart extends Component {
  constructor(props) {
    super(props)

    this.margin = { top: 10, right: 25, bottom: 50, left: 50 }
    this.geometry = {
      labelWidth: 20,
      axisWidth: 40,
      contentHeight: 170,
    }
    this.geometry.contentWidth =
      this.props.width - this.geometry.labelWidth - this.geometry.axisWidth

    this.yScale = d3
      .scaleLinear()
      .range([this.geometry.contentHeight, 0])
      .domain([0, 300])
    this.xScale = d3
      .scaleBand()
      .range([0, this.geometry.contentWidth])
      .padding(0.2)
  }

  componentDidMount() {
    d3.select('svg#yAxis')
      .append('g')
      .attr('class', 'axis yAxis')
      .attr('transform', `translate(39, 0)`)
      .call(d3.axisLeft(this.yScale))

    // xScale and axis

    d3.select('svg#xAxis')
      .append('g')
      .attr('class', 'axis xAxis')
      .call(d3.axisBottom(this.xScale))
  }

  render() {
    // update xScale with the # of data objects
    this.xScale.domain(
      this.props.data.map(function(d) {
        return d.id
      })
    )

    // update xScale svg
    d3.select('svg#xAxis')
      .select('g.axis')
      .remove()
    d3.select('svg#xAxis')
      .append('g')
      .attr('class', 'axis xAxis')
      .call(d3.axisBottom(this.xScale))

    return (
      <div
        className="reactBarChart"
        style={{
          display: 'flex',
          height: this.geometry.contentHeight,
          width: this.props.width,
          flexWrap: 'wrap',
          fontFamily: 'IBM Plex Mono',
          fontSize: 11,
          alignItems: 'stretch',
          // outline: '1px solid purple',
        }}
      >
        <div
          style={{
            // outline: '1px solid pink',
            width: this.geometry.labelWidth,
            // display: 'flex',
            // alignItems: 'center',
            // transform: 'rotate(-90deg)',
            paddingTop: '50%',
          }}
        >
          <div
            style={{
              width: this.geometry.contentHeight,
              marginTop: '-100%',
              whiteSpace: 'nowrap',
              textAlign: 'center',
              marginLeft: this.geometry.contentHeight * -0.5,
            }}
          >
            Verbindungen
          </div>
        </div>
        <div
          style={{
            width: this.geometry.axisWidth,
          }}
        >
          <svg
            width={this.geometry.axisWidth}
            height={this.geometry.contentHeight}
            style={{ overflow: 'visible' }}
            id="yAxis"
          />
        </div>
        <CSSTransitionGroup
          transitionName="bars"
          transitionEnterTimeout={500}
          transitionLeaveTimeout={300}
          style={{
            // outline: '1px solid green',
            flex: 1,
            height: this.geometry.contentHeight,
            width: this.geometry.contentWidth,
            overflow: 'hidden',
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'space-evenly',
          }}
        >
          {this.props.data.map(d => (
            <div
              key={d.id}
              className="animateChange"
              style={{
                backgroundColor: 'orange',
                color: 'white',
                fontSize: 12,
                textAlign: 'center',
                height: this.yScale(300 - d.local),
                width: this.xScale.bandwidth(),
              }}
            >
              <span style={{ display: 'inline-block', marginTop: 5 }}>
                {d.local}
              </span>
            </div>
          ))}
        </CSSTransitionGroup>
        <div
          style={{
            flex: '1 1 100%',
            height: this.geometry.axisWidth / 1.5,
            marginLeft: this.geometry.labelWidth + this.geometry.axisWidth,
          }}
        >
          <svg
            width={this.geometry.contentWidth}
            height={this.geometry.axisWidth / 1.5}
            style={{ overflow: 'visible' }}
            id="xAxis"
          />
        </div>
        <div
          style={{
            flex: 1,
            height: this.geometry.labelWidth,
            marginLeft: this.geometry.labelWidth + this.geometry.axisWidth,
          }}
        >
          bottom label
        </div>
      </div>
    )
  }
}

export default ReactBarChart
