import React, { Component } from 'react'
import '../static/css/svgstyles.css'

// Charts
import BarChart from '../charts/BarChart'
import NivoBarChart from '../charts/NivoBarChart'
import RechartsBarChart from '../charts/RechartsBarChart'
import ReactBarChart from '../charts/ReactBarChart'

import styles from '../static/css/ExampleGraphs.module.css'

// Material UI
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'

class ExampleGraphs extends Component {
  constructor(props) {
    super(props)

    this.state = {
      data: [
        {
          id: 'Eins',
          local: 10,
          national: 20,
          eu: 30,
        },
        {
          id: 'Zwei',
          local: 20,
          national: 30,
          eu: 40,
        },
        {
          id: 'Drei',
          local: 30,
          national: 40,
          eu: 50,
        },
      ],
      keys: ['local', 'national', 'eu'],
      colorByEbene: true,
    }
  }

  randomNumber(min, max) {
    return Math.round(Math.random() * (max - min) + min)
  }

  changeData() {
    const possibleCities = ['Eins', 'Zwei', 'Drei', 'Vier', 'Funf']

    const howManyCities = this.randomNumber(1, possibleCities.length)
    const availableData = [
      {
        id: 'Eins',
        local: this.randomNumber(0, 100),
        national: this.randomNumber(0, 100),
        eu: this.randomNumber(0, 100),
      },
      {
        id: 'Zwei',
        local: this.randomNumber(0, 100),
        national: this.randomNumber(0, 100),
        eu: this.randomNumber(0, 100),
      },
      {
        id: 'Drei',
        local: this.randomNumber(0, 100),
        national: this.randomNumber(0, 100),
        eu: this.randomNumber(0, 100),
      },
      {
        id: 'Vier',
        local: this.randomNumber(0, 100),
        national: this.randomNumber(0, 100),
        eu: this.randomNumber(0, 100),
      },
      {
        id: 'Funf',
        local: this.randomNumber(0, 100),
        national: this.randomNumber(0, 100),
        eu: this.randomNumber(0, 100),
      },
    ]

    let newData = []
    for (let index = 1; index <= howManyCities; index++) {
      newData.push(availableData[index - 1])
    }

    this.setState(
      {
        data: newData,
        // colorByEbene: this.randomNumber(0, 2),
      },
      () => {
        // console.log(`%cState has updated:`, 'background-color: Aquamarine;')
        // console.log(this.state)
      }
    )

    // console.log(this.refs.theChart.state)
    // this.refs.theChart.changeData(newData)
  }

  render() {
    return (
      <div>
        <Typography variant="h3" gutterBottom>
          Beispielgrafen
        </Typography>
        <div className={styles.invisiblePaper} style={{ flex: 1 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={this.changeData.bind(this)}
          >
            Randomize Data
          </Button>
        </div>
        {
          // 3 Graphs
        }
        <div
          className="chartsGroup"
          style={{
            display: 'flex',
            height: 800,
            // alignItems: 'stretch',
            flexWrap: 'wrap',
          }}
        >
          {
            // Custom React Chart (HTML, little d3)
          }
          <Paper className={styles.paper} style={{ flex: 1 }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: '1 1 200px',
                height: 300,
              }}
            >
              <Typography
                variant="h5"
                style={{
                  marginBottom: 10,
                }}
              >
                own chart (react)
              </Typography>
              <div style={{ flexGrow: 1 }}>
                <ReactBarChart
                  data={this.state.data}
                  id="reactbarchart"
                  width={250}
                  height={230}
                />
              </div>
            </div>
          </Paper>
          {
            // own chart
          }
          <Paper className={styles.paper} style={{ flex: 1 }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: '1 1 200px',
                height: 300,
              }}
            >
              <Typography variant="h5">own chart (d3 in svg)</Typography>
              <div style={{ flexGrow: 1 }}>
                <BarChart
                  data={this.state.data}
                  id="d3barchart"
                  width={250}
                  height={230}
                />
              </div>
            </div>
          </Paper>
          {
            // Rechart
          }
          <Paper className={styles.paper} style={{ flex: 1 }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: '1 1 200px',
                height: 300,
              }}
            >
              <Typography variant="h5">rechart bar char</Typography>
              <div style={{ flexGrow: 1 }}>
                <RechartsBarChart data={this.state.data} />
              </div>
            </div>
          </Paper>{' '}
          {
            //  custom nivo Barchart
          }
          <Paper className={styles.paper} style={{ flex: 1 }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: '1 1 200px',
                height: 300,
              }}
            >
              <Typography variant="h5">nivo bar chart</Typography>
              <div className="nivoChart" style={{ flexGrow: 1 }}>
                <NivoBarChart
                  data={this.state.data}
                  keys={this.state.keys}
                  colorByEbene={this.state.colorByEbene}
                  ref="theChart"
                />
              </div>
            </div>
          </Paper>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(ExampleGraphs)
