import React, { Component } from 'react'

import RemoveButton from './RemoveButton'

class List extends Component {
  clickHandler(index, item) {
    console.log('you clicked on an item:', index)
    // when the prop sent into the component is a function, call it back
    // with information from the clicked element
    if (typeof this.props.onRemove === 'function') {
      // parent function is expecting an index to be removed from state
      this.props.onRemove(index)
    }
  }


  render() {
    return (
      <ul>
        {this.props.items.map((item, index) => (
          <li className="item" key={index}>
            {item}
            <RemoveButton
              index={index}
              onRemove={this.clickHandler.bind(this)}
              // onClick={this.clickHandler.bind(this)}
              colorOfButton={this.props.colorOfButton}
            />
          </li>
        ))}
      </ul>
    )
  }
}

export default List
