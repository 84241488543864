import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'

import './static/css/Global.css'

import * as serviceWorker from './serviceWorker'

// material UI theming
// https://material-ui.com/customization/default-theme/
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#1e88e5',
    },
    secondary: {
      main: '#689f38',
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: ['IBM Plex Sans', 'Roboto', 'sans-serif'].join(','),
  },
  h2: {
    fontSize: '3rem',
    color: 'red',
  },
  // spacing: {
  //   unit: 16,
  // },
  shape: {
    borderRadius: 2,
  },
})

theme.shadows[1] = '0px 0px 1px 0px rgba(0,0,0,0.3)'

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <CssBaseline>
      <App />
    </CssBaseline>
  </MuiThemeProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
