import React, { Component } from 'react'
import classNames from 'classnames'

// App Components
import SideMenu from './components/SideMenu'

// MaterialUI
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Drawer from '@material-ui/core/Drawer'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'

// Icons
// import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

// Pages & Routing
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import ConnectionsExplorer from './pages/ConnectionsExplorer'
import PageTwo from './pages/PageTwo'
import PageThree from './pages/PageThree'
import ExampleGraphs from './pages/ExampleGraphs'
import WritingProgress from './pages/WritingProgress'
import TestLists from './pages/TestLists'

// styling and theming
import { withStyles } from '@material-ui/core/styles'
import styles from './static/css/App.module.css'

const drawerWidth = 240
const localStyles = theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    width: `calc(100% - ${theme.spacing.unit * 7}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing.unit * 7,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 7,
    },
  },
})

class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      drawerOpen: false,
    }
  }

  handleDrawerOpen = () => {
    this.setState({ drawerOpen: true })
  }

  handleDrawerClose = () => {
    this.setState({ drawerOpen: false })
  }

  render() {
    const { classes } = this.props

    return (
      <BrowserRouter>
        <div className={styles.root}>
          {
            // App Structure
          }
          <AppBar
            position="absolute"
            className={classNames(
              classes.appBar,
              this.state.drawerOpen && classes.appBarShift
            )}
            elevation={10}
          >
            <Toolbar
              // disableGutters={!this.state.drawerOpen}
              className={styles.toolbar}
            >
              {/*
              <IconButton
                color='inherit'
                aria-label='Open drawer'
                onClick={this.handleDrawerOpen.bind(this)}
                className={classNames(
                  styles.menuButton,
                  this.state.drawerOpen && styles.menuButtonHidden
                )}>
                <MenuIcon />
              </IconButton>
              */}
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                className={styles.title}
              >
                Netzwerkgrafik
              </Typography>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="permanent"
            classes={{
              paper: classNames(
                classes.drawerPaper,
                !this.state.drawerOpen && classes.drawerPaperClose
              ),
            }}
            open={this.state.open}
          >
            <div className={styles.toolbarIcon}>
              <IconButton
                className={classNames(
                  styles.toolbarIcon,
                  !this.state.drawerOpen && styles.toolbarIconHidden
                )}
                onClick={this.handleDrawerClose}
              >
                <ChevronLeftIcon />
              </IconButton>
              <IconButton
                className={classNames(
                  styles.toolbarIcon,
                  this.state.drawerOpen && styles.toolbarIconHidden
                )}
                onClick={this.handleDrawerOpen}
              >
                <ChevronRightIcon />
              </IconButton>
            </div>
            <Divider />
            <SideMenu />
          </Drawer>
          {
            // Main Content
          }
          <main className={styles.content}>
            <Switch>
              <Route exact path="/progress" component={WritingProgress} />
              <Route exact path="/examples" component={ExampleGraphs} />
              <Route path="/tests" component={TestLists} />
              <Route path="/" component={ConnectionsExplorer} />
              <Route path="/two" component={PageTwo} />
              <Route path="/three" component={PageThree} />
            </Switch>
          </main>
        </div>
      </BrowserRouter>
    )
  }
}

export default withStyles(localStyles)(App)
