import React, { Component } from 'react'

import Typography from '@material-ui/core/Typography'

export default class PageThree extends Component {
  render() {
    return (
      <div>
        <Typography variant='h3' gutterBottom>
          Dritte Seite
        </Typography>
        <div>This is page three.</div>
      </div>
    )
  }
}
