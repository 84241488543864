import React, { Component } from 'react'

import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
// Forms
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Chip from '@material-ui/core/Chip'

import * as _ from 'lodash'

// DATA IMPORT
import data from '../data/network'

// https://medium.com/@Elijah_Meeks/interactive-applications-with-react-d3-f76f7b3ebc71

export default class PageOne extends Component {
  constructor(props) {
    super(props)

    this.state = {
      interestedCities: data.cities,
      cityOfInterest: data.cities[0].name,
    }

    // console.log(data.network)
    // console.log('cities', data.cities)
  }

  // array to define table column order
  tableColumns = [
    {
      key: 'contactOriginal',
      label: 'Kontakt',
    },
    {
      key: 'contactFine',
      label: 'Kontakt (Fein)',
    },
    {
      key: 'contactCoarse',
      label: 'Kontakt (Grob)',
    },
    {
      key: 'actorType',
      label: 'Akteurs-Art',
    },

    {
      key: 'importance',
      label: 'Wichtigkeit',
    },
  ]

  componentDidMount() {}

  handleFormControlChange = event => {
    this.setState({ [event.target.name]: event.target.value }, () => {
      // after state has changed …
      // find the selected city in cities array and add to interetsted Cities
      // but only if not already exists
      if (
        this.state.interestedCities.find(d => d.name === event.target.value)
      ) {
        console.log('selected city already in city array. doing nothing')
        return
      }

      const cityData = data.cities.find(d => d.name === event.target.value)
      this.setState((state, props) => {
        return {
          interestedCities: [...state.interestedCities, cityData],
        }
      })
    })
  }

  handleDeleteChip = data => () => {
    this.setState((state, props) => {
      return {
        interestedCities: state.interestedCities.filter(
          city => city.name !== data.name
        ),
      }
    })
  }

  render() {
    console.log(this.state.interestedCities)

    return (
      <div>
        <Typography variant="h3" gutterBottom>
          Verbindungsexplorer
        </Typography>
        <Paper
          className="paper flush"
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography variant="h6">Auswahl</Typography>
          <div className="verticalPadding">
            <form>
              <FormControl>
                <InputLabel htmlFor="city-to-add">Stadt hinzufügen</InputLabel>
                <Select
                  value={this.state.cityOfInterest}
                  onChange={this.handleFormControlChange}
                  inputProps={{
                    name: 'cityOfInterest',
                    id: 'city-to-add',
                  }}
                  style={{ width: 200 }}
                >
                  {data.cities.map(city => (
                    <MenuItem value={city.name} key={city.name}>
                      {city.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </form>
          </div>
          <div className="verticalPadding">
            {_.sortBy(this.state.interestedCities, [
              function(o) {
                return o.name
              },
            ]).map(interestedCity => (
              <Chip
                key={interestedCity.name}
                label={interestedCity.name}
                onClick={this.handleDeleteChip(interestedCity)}
                onDelete={this.handleDeleteChip(interestedCity)}
                className="chip"
              />
            ))}
          </div>
        </Paper>
        <div className="paper flush">
          <table
            style={{
              minWidth: '100%',
              borderWidth: 0,
              borderSpacing: 1,
              fontFamily: 'IBM Plex Sans',
            }}
          >
            <thead
              style={{
                backgroundColor: '#eee',
                textAlign: 'left',
              }}
            >
              <tr>
                {this.tableColumns.map(item => (
                  <th key={item.key} style={{ height: 50, padding: '0 4px' }}>
                    {item.label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {this.state.interestedCities.map(interestedCity => {
                const dataForCity = data.network.filter(
                  row => row.city === interestedCity.name
                )
                const header = (
                  <tr key={`${interestedCity.name}`}>
                    <td
                      colSpan={99}
                      style={{
                        backgroundColor: 'lightsteelblue',
                        height: 40,
                        padding: 4,
                      }}
                    >
                      <span style={{ fontWeight: 700 }}>
                        {interestedCity.name} ({interestedCity.sumConnections}{' '}
                        Verbindungen)
                      </span>
                    </td>
                  </tr>
                )

                let rows = []
                dataForCity.map((data, index) => {
                  const el = (
                    <tr key={`${data.city}-${index}`}>
                      {this.tableColumns.map(column => (
                        <td
                          key={`${data.city}-${index}-${column.key}`}
                          style={{
                            padding: '4px',
                            borderBottom: '1px solid grey',
                          }}
                        >
                          {data[column.key]}
                        </td>
                      ))}
                    </tr>
                  )
                  return rows.push(el)
                })

                return [header, rows]
              })}
              {/*dataToShow.map((row, index) => (
                <tr key={`${row.name}-${index}`}>
                  {this.tableColumns.map((item, index) => (
                    <td
                      key={`${row.name}-${item.label}`}
                      style={{ whiteSpace: 'nowrap', padding: '0 4px' }}
                    >
                      {row[item.key]}
                    </td>
                  ))}
                </tr>
                  ))*/}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}
