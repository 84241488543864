import React, { Component } from 'react'

import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
// import FormLabel from '@material-ui/core/FormLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'

import * as d3 from 'd3'

import {
  ResponsiveContainer,
  ComposedChart,
  Bar,
  Cell,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts'

// import Utils from '../utils/Utils'
import styles from '../static/css/WritingProgress.module.css'

// DATA
import data from '../data/metadata'

export default class WritingProgress extends Component {
  constructor(props) {
    super(props)

    this.state = {
      includeWritingPauses: false,
      dataSet: 'words',
    }
  }

  handleChangeSwitch = name => event => {
    this.setState({ [name]: event.target.checked })
  }

  handleChangeChooseSet = event => {
    this.setState({ dataSet: event.target.value })
  }

  render() {
    // console.log('progress data', data.progress)

    // filter data when needed
    let chartData
    if (this.state.includeWritingPauses) {
      chartData = data.progress
    } else {
      if (this.state.dataSet === 'words')
        chartData = data.progress.filter(row => row.words !== 0)
      else chartData = data.progress.filter(row => row.characters !== 0)
    }

    let dataKeyForTotal
    this.state.dataSet === 'words'
      ? (dataKeyForTotal = 'totalWords')
      : (dataKeyForTotal = 'totalCharacters')

    let opacityScale = d3
      .scalePow()
      .domain([0, d3.max(data.progress, row => row.words)])
      .range([0, 1])
      .exponent(0.4)

    let lastMonth
    const months = [
      'Jan',
      'Feb',
      'Mär',
      'Apr',
      'Mai',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Okt',
      'Nov',
      'Dez',
    ]

    return (
      <div>
        <Typography variant="h3" gutterBottom>
          Schreib-Entwicklung
        </Typography>
        <Paper className="paper">
          <Typography variant="h6">Länge</Typography>
          <div
            className="chartSettings"
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={this.state.includeWritingPauses}
                  onChange={this.handleChangeSwitch('includeWritingPauses')}
                  value="includeWritingPauses"
                  color="primary"
                />
              }
              label="Mit Pausen"
              style={{
                marginRight: 24,
              }}
            />
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <RadioGroup
                aria-label="Berechnen"
                name="dataSet"
                value={this.state.dataSet}
                onChange={this.handleChangeChooseSet}
                style={{ flexDirection: 'row' }}
              >
                <FormControlLabel
                  value="words"
                  control={<Radio />}
                  label="Wörter"
                />
                <FormControlLabel
                  value="characters"
                  control={<Radio />}
                  label="Zeichen"
                />
              </RadioGroup>
            </div>
          </div>
          <ResponsiveContainer width="100%" height={400} className="rechart">
            <ComposedChart
              data={chartData}
              margin={{
                top: 20,
                right: 50,
                left: 10,
                bottom: 20,
              }}
            >
              <CartesianGrid horizontal={false} />
              <XAxis dataKey="dateLocale" />
              <YAxis
                yAxisId="delta"
                dataKey={this.state.dataSet}
                orientation="left"
              />
              <Bar
                yAxisId="delta"
                dataKey={this.state.dataSet}
                name="Geschrieben"
                isAnimationActive={false}
              >
                {// when characters is negative, it's all we need to know to draw a red bar
                chartData.map((entry, index) => {
                  return (
                    <Cell
                      key={`cell-${index}`}
                      fill={entry.characters < 0 ? '#fb8c00' : '#8bc34a'}
                    />
                  )
                })}
              </Bar>
              <YAxis
                yAxisId="total"
                dataKey={dataKeyForTotal}
                orientation="right"
                domain={[0, 'dataMax']}
              />
              <Line
                yAxisId="total"
                type="step"
                dot={false}
                name="Gesamt"
                dataKey={dataKeyForTotal}
                isAnimationActive={false}
                strokeWidth="2"
                stroke="#039be5"
              />
              <Tooltip />
              <Legend layout="horizontal" />
            </ComposedChart>
          </ResponsiveContainer>
        </Paper>
        <Paper className="paper">
          <Typography variant="h6">Kalender</Typography>
          <div
            style={{
              display: 'flex',
            }}
          >
            <div className={styles.dayhead}>
              <div />
              <div className={styles.legend}>Mo</div>
              <div className={styles.legend}>Di</div>
              <div className={styles.legend}>Mi</div>
              <div className={styles.legend}>Do</div>
              <div className={styles.legend}>Fr</div>
              <div className={styles.legend}>Sa</div>
              <div className={styles.legend}>So</div>
            </div>
            <div className={styles.daycells}>
              {data.progress.map((row, index) => {
                // console.log(index % 7)
                let header
                if (index % 7 === 0) {
                  header = <div key={`${index}`} className={styles.month} />
                  const rowDate = new Date(row.date)
                  const monthOfRow = rowDate.getMonth()
                  if (lastMonth !== monthOfRow) {
                    lastMonth = monthOfRow
                    header = (
                      <div
                        key={`${index}-${monthOfRow}`}
                        className={styles.month}
                      >
                        {months[monthOfRow]}
                      </div>
                    )
                  }
                }
                let cell = (
                  <div
                    key={`${index}-${row.dateLocale}`}
                    className={styles.cell}
                    alt={new Date(row.date).toLocaleDateString('de-DE', {
                      weekday: 'long',
                    })}
                  >
                    <div
                      className={styles.indicator}
                      style={{
                        opacity: opacityScale(row.words),
                      }}
                    />
                  </div>
                )
                return [header, cell]
              })}
            </div>
          </div>
        </Paper>
      </div>
    )
  }
}
