import React, { Component } from 'react'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import ListItemIcon from '@material-ui/core/ListItemIcon'

import Looks1Icon from '@material-ui/icons/LooksOne'
import Looks2Icon from '@material-ui/icons/LooksTwo'
import Looks3Icon from '@material-ui/icons/Looks3'
import BarChartIcon from '@material-ui/icons/BarChart'
import AssignmentIcon from '@material-ui/icons/Assignment'
import BlurLinearIcon from '@material-ui/icons/BlurLinear'
// import ViewListIcon from '@material-ui/icons/ViewList'

import { NavLink } from 'react-router-dom'

export default class SideMenu extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    return (
      <div id="sidemenu">
        <List>
          <ListItem button component={NavLink} exact to="/">
            <ListItemIcon>
              <Looks1Icon />
            </ListItemIcon>
            <ListItemText primary="Verbindungsexplorer" />
          </ListItem>
          <ListItem button component={NavLink} exact to="/two">
            <ListItemIcon>
              <Looks2Icon />
            </ListItemIcon>
            <ListItemText primary="Zweite Seite" />
          </ListItem>
          <ListItem button component={NavLink} exact to="/three">
            <ListItemIcon>
              <Looks3Icon />
            </ListItemIcon>
            <ListItemText primary="Dritte Seite" />
          </ListItem>
        </List>
        <Divider />
        <ListSubheader inset>Weiteres</ListSubheader>
        <List>
          <ListItem button component={NavLink} exact to="/progress">
            <ListItemIcon>
              <BlurLinearIcon />
            </ListItemIcon>
            <ListItemText primary="Schreib-Entwicklung" />
          </ListItem>
          <ListItem button component={NavLink} exact to="/examples">
            <ListItemIcon>
              <BarChartIcon />
            </ListItemIcon>
            <ListItemText primary="Beispielgrafen" />
          </ListItem>
          <ListItem button component={NavLink} exact to="/tests">
            <ListItemIcon>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary="Listentest" />
          </ListItem>
        </List>
      </div>
    )
  }
}
