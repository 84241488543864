// https://www.csvjson.com/csv2json

import * as _ from 'lodash'

class WordMetadata {
  constructor() {
    this.outside = ['one', 'two', 'three', 'four']

    this.inside = [
      [1.0, 1.1, 1.2, 1.3, 1.4, 1.5],
      [2.0, 2.1, 2.2, 2.3, 2.4, 2.5],
      [3.0, 3.1, 3.2, 3.3, 3.4, 3.5],
      [4.0, 4.1, 4.2, 4.3, 4.4, 4.5],
      [5.0, 5.1, 5.2, 5.3, 5.4, 5.5],
    ]

    this.progress = [
      {
        date: '21.11.17', // 21.11.17
        _words: 0,
        'words other': 133,
        words: 133,
        _characters: 0,
        'characters other': 0,
        characters: 1273,
        target: 133,
      },
      {
        date: '28.11.17',
        _words: 0,
        'words other': 437,
        words: 437,
        _characters: 0,
        'characters other': 0,
        characters: 3554,
        target: 437,
      },
      {
        date: '29.11.17',
        _words: 0,
        'words other': 311,
        words: 311,
        _characters: 0,
        'characters other': 0,
        characters: 2437,
        target: 311,
      },
      {
        date: '30.11.17',
        _words: 0,
        'words other': 370,
        words: 370,
        _characters: 0,
        'characters other': 0,
        characters: 2746,
        target: 370,
      },
      {
        date: '01.12.17',
        _words: 0,
        'words other': 21,
        words: 21,
        _characters: 0,
        'characters other': 0,
        characters: 198,
        target: 21,
      },
      {
        date: '04.12.17',
        _words: 0,
        'words other': 372,
        words: 372,
        _characters: 0,
        'characters other': 0,
        characters: 3170,
        target: 372,
      },
      {
        date: '05.12.17',
        _words: 0,
        'words other': 60,
        words: 60,
        _characters: 0,
        'characters other': 0,
        characters: 474,
        target: 60,
      },
      {
        date: '12.12.17',
        _words: 0,
        'words other': 47,
        words: 47,
        _characters: 0,
        'characters other': 0,
        characters: 358,
        target: 47,
      },
      {
        date: '14.12.17',
        _words: 0,
        'words other': 152,
        words: 152,
        _characters: 0,
        'characters other': 0,
        characters: 1339,
        target: 152,
      },
      {
        date: '11.01.18',
        _words: 0,
        'words other': 30,
        words: 30,
        _characters: 0,
        'characters other': 0,
        characters: 274,
        target: 30,
      },
      {
        date: '22.01.18',
        _words: 0,
        'words other': 185,
        words: 185,
        _characters: 0,
        'characters other': 0,
        characters: 1186,
        target: 185,
      },
      {
        date: '23.01.18',
        _words: 0,
        'words other': 40,
        words: 40,
        _characters: 0,
        'characters other': 0,
        characters: 312,
        target: 40,
      },
      {
        date: '25.01.18',
        _words: 0,
        'words other': 13,
        words: 13,
        _characters: 0,
        'characters other': 0,
        characters: 85,
        target: 13,
      },
      {
        date: '31.01.18',
        _words: 0,
        'words other': 400,
        words: 400,
        _characters: 0,
        'characters other': 0,
        characters: 2567,
        target: 400,
      },
      {
        date: '01.02.18',
        _words: 0,
        'words other': 13,
        words: 13,
        _characters: 0,
        'characters other': 0,
        characters: 91,
        target: 13,
      },
      {
        date: '06.02.18',
        _words: 0,
        'words other': -250,
        words: -250,
        _characters: 0,
        'characters other': 0,
        characters: -1724,
        target: -250,
      },
      {
        date: '09.02.18',
        _words: 0,
        'words other': 51,
        words: 51,
        _characters: 0,
        'characters other': 0,
        characters: 404,
        target: 51,
      },
      {
        date: '20.02.18',
        _words: 0,
        'words other': 389,
        words: 389,
        _characters: 0,
        'characters other': 0,
        characters: 2034,
        target: 389,
      },
      {
        date: '21.02.18',
        _words: 0,
        'words other': 60,
        words: 60,
        _characters: 0,
        'characters other': 0,
        characters: 380,
        target: 60,
      },
      {
        date: '23.02.18',
        _words: 0,
        'words other': 13,
        words: 13,
        _characters: 0,
        'characters other': 0,
        characters: -59,
        target: 13,
      },
      {
        date: '27.02.18',
        _words: 0,
        'words other': 268,
        words: 268,
        _characters: 0,
        'characters other': 0,
        characters: 1786,
        target: 268,
      },
      {
        date: '28.02.18',
        _words: 0,
        'words other': 145,
        words: 145,
        _characters: 0,
        'characters other': 0,
        characters: 1054,
        target: 145,
      },
      {
        date: '01.03.18',
        _words: 0,
        'words other': 393,
        words: 393,
        _characters: 0,
        'characters other': 0,
        characters: 3056,
        target: 393,
      },
      {
        date: '05.03.18',
        _words: 0,
        'words other': 49,
        words: 49,
        _characters: 0,
        'characters other': 0,
        characters: 351,
        target: 49,
      },
      {
        date: '06.03.18',
        _words: 0,
        'words other': 418,
        words: 418,
        _characters: 0,
        'characters other': 0,
        characters: 3292,
        target: 418,
      },
      {
        date: '07.03.18',
        _words: 0,
        'words other': 0,
        words: 0,
        _characters: 0,
        'characters other': 0,
        characters: 1,
        target: 0,
      },
      {
        date: '08.03.18',
        _words: 0,
        'words other': 207,
        words: 207,
        _characters: 0,
        'characters other': 0,
        characters: 1630,
        target: 207,
      },
      {
        date: '09.03.18',
        _words: 0,
        'words other': 350,
        words: 350,
        _characters: 0,
        'characters other': 0,
        characters: 2669,
        target: 350,
      },
      {
        date: '15.03.18',
        _words: 0,
        'words other': 634,
        words: 634,
        _characters: 0,
        'characters other': 0,
        characters: 5059,
        target: 634,
      },
      {
        date: '20.03.18',
        _words: 0,
        'words other': 475,
        words: 475,
        _characters: 0,
        'characters other': 0,
        characters: 3600,
        target: 475,
      },
      {
        date: '21.03.18',
        _words: 0,
        'words other': 0,
        words: 0,
        _characters: 0,
        'characters other': 0,
        characters: 2,
        target: 0,
      },
      {
        date: '22.03.18',
        _words: 0,
        'words other': 448,
        words: 448,
        _characters: 0,
        'characters other': 0,
        characters: 3459,
        target: 448,
      },
      {
        date: '23.03.18',
        _words: 0,
        'words other': 235,
        words: 235,
        _characters: 0,
        'characters other': 0,
        characters: 2121,
        target: 235,
      },
      {
        date: '27.03.18',
        _words: 0,
        'words other': 495,
        words: 495,
        _characters: 0,
        'characters other': 0,
        characters: 3497,
        target: 495,
      },
      {
        date: '02.04.18',
        _words: 0,
        'words other': 87,
        words: 87,
        _characters: 0,
        'characters other': 0,
        characters: 622,
        target: 87,
      },
      {
        date: '03.04.18',
        _words: 0,
        'words other': 176,
        words: 176,
        _characters: 0,
        'characters other': 0,
        characters: 1367,
        target: 176,
      },
      {
        date: '04.04.18',
        _words: 0,
        'words other': 72,
        words: 72,
        _characters: 0,
        'characters other': 0,
        characters: 613,
        target: 72,
      },
      {
        date: '06.04.18',
        _words: 0,
        'words other': 59,
        words: 59,
        _characters: 0,
        'characters other': 0,
        characters: 523,
        target: 59,
      },
      {
        date: '09.04.18',
        _words: 0,
        'words other': 559,
        words: 559,
        _characters: 0,
        'characters other': 0,
        characters: 3871,
        target: 559,
      },
      {
        date: '10.04.18',
        _words: 0,
        'words other': 252,
        words: 252,
        _characters: 0,
        'characters other': 0,
        characters: 2007,
        target: 252,
      },
      {
        date: '11.04.18',
        _words: 0,
        'words other': 842,
        words: 842,
        _characters: 0,
        'characters other': 0,
        characters: 6210,
        target: 842,
      },
      {
        date: '12.04.18',
        _words: 0,
        'words other': 612,
        words: 612,
        _characters: 0,
        'characters other': 0,
        characters: 4577,
        target: 612,
      },
      {
        date: '13.04.18',
        _words: 0,
        'words other': 341,
        words: 341,
        _characters: 0,
        'characters other': 0,
        characters: 2886,
        target: 341,
      },
      {
        date: '16.04.18',
        _words: 0,
        'words other': 18,
        words: 18,
        _characters: 0,
        'characters other': 0,
        characters: 279,
        target: 18,
      },
      {
        date: '17.04.18',
        _words: 0,
        'words other': 391,
        words: 391,
        _characters: 0,
        'characters other': 0,
        characters: 3144,
        target: 391,
      },
      {
        date: '18.04.18',
        _words: 0,
        'words other': 215,
        words: 215,
        _characters: 0,
        'characters other': 0,
        characters: 1791,
        target: 215,
      },
      {
        date: '19.04.18',
        _words: 0,
        'words other': 425,
        words: 425,
        _characters: 0,
        'characters other': 0,
        characters: 3413,
        target: 425,
      },
      {
        date: '20.04.18',
        _words: 0,
        'words other': 495,
        words: 495,
        _characters: 0,
        'characters other': 0,
        characters: 3882,
        target: 495,
      },
      {
        date: '23.04.18',
        _words: 0,
        'words other': 26,
        words: 26,
        _characters: 0,
        'characters other': 0,
        characters: 187,
        target: 26,
      },
      {
        date: '26.04.18',
        _words: 0,
        'words other': 234,
        words: 234,
        _characters: 0,
        'characters other': 0,
        characters: 1801,
        target: 234,
      },
      {
        date: '27.04.18',
        _words: 0,
        'words other': -17,
        words: -17,
        _characters: 0,
        'characters other': 0,
        characters: -114,
        target: -17,
      },
      {
        date: '03.05.18',
        _words: 0,
        'words other': 5,
        words: 5,
        _characters: 0,
        'characters other': 0,
        characters: 74,
        target: 5,
      },
      {
        date: '04.05.18',
        _words: 0,
        'words other': -19,
        words: -19,
        _characters: 0,
        'characters other': 0,
        characters: -115,
        target: -19,
      },
      {
        date: '09.05.18',
        _words: 0,
        'words other': 196,
        words: 196,
        _characters: 0,
        'characters other': 0,
        characters: 1655,
        target: 196,
      },
      {
        date: '10.05.18',
        _words: 0,
        'words other': 491,
        words: 491,
        _characters: 0,
        'characters other': 0,
        characters: 3727,
        target: 491,
      },
      {
        date: '11.05.18',
        _words: 0,
        'words other': 99,
        words: 99,
        _characters: 0,
        'characters other': 0,
        characters: 726,
        target: 99,
      },
      {
        date: '14.05.18',
        _words: 0,
        'words other': 31,
        words: 31,
        _characters: 0,
        'characters other': 0,
        characters: 322,
        target: 31,
      },
      {
        date: '15.05.18',
        _words: 0,
        'words other': 130,
        words: 130,
        _characters: 0,
        'characters other': 0,
        characters: 962,
        target: 130,
      },
      {
        date: '16.05.18',
        _words: 0,
        'words other': 466,
        words: 466,
        _characters: 0,
        'characters other': 0,
        characters: 3642,
        target: 466,
      },
      {
        date: '17.05.18',
        _words: 0,
        'words other': 333,
        words: 333,
        _characters: 0,
        'characters other': 0,
        characters: 2681,
        target: 333,
      },
      {
        date: '18.05.18',
        _words: 0,
        'words other': 558,
        words: 558,
        _characters: 0,
        'characters other': 0,
        characters: 4120,
        target: 558,
      },
      {
        date: '21.05.18',
        _words: 0,
        'words other': 217,
        words: 217,
        _characters: 0,
        'characters other': 0,
        characters: 1631,
        target: 217,
      },
      {
        date: '22.05.18',
        _words: 0,
        'words other': 105,
        words: 105,
        _characters: 0,
        'characters other': 0,
        characters: 850,
        target: 105,
      },
      {
        date: '23.05.18',
        _words: 0,
        'words other': 96,
        words: 96,
        _characters: 0,
        'characters other': 0,
        characters: 891,
        target: 96,
      },
      {
        date: '28.05.18',
        _words: 0,
        'words other': 1,
        words: 1,
        _characters: 0,
        'characters other': 0,
        characters: 16,
        target: 1,
      },
      {
        date: '29.05.18',
        _words: 0,
        'words other': 372,
        words: 372,
        _characters: 0,
        'characters other': 0,
        characters: 3012,
        target: 372,
      },
      {
        date: '30.05.18',
        _words: 0,
        'words other': 118,
        words: 118,
        _characters: 0,
        'characters other': 0,
        characters: 891,
        target: 118,
      },
      {
        date: '31.05.18',
        _words: 0,
        'words other': 0,
        words: 0,
        _characters: 0,
        'characters other': 0,
        characters: 1,
        target: 0,
      },
      {
        date: '01.06.18',
        _words: 0,
        'words other': 14,
        words: 14,
        _characters: 0,
        'characters other': 0,
        characters: 134,
        target: 14,
      },
      {
        date: '04.06.18',
        _words: 0,
        'words other': 180,
        words: 180,
        _characters: 0,
        'characters other': 0,
        characters: 1270,
        target: 180,
      },
      {
        date: '05.06.18',
        _words: 0,
        'words other': 227,
        words: 227,
        _characters: 0,
        'characters other': 0,
        characters: 1924,
        target: 227,
      },
      {
        date: '06.06.18',
        _words: 0,
        'words other': -25,
        words: -25,
        _characters: 0,
        'characters other': 0,
        characters: -131,
        target: -25,
      },
      {
        date: '07.06.18',
        _words: 0,
        'words other': 288,
        words: 288,
        _characters: 0,
        'characters other': 0,
        characters: 2205,
        target: 288,
      },
      {
        date: '08.06.18',
        _words: 0,
        'words other': 61,
        words: 61,
        _characters: 0,
        'characters other': 0,
        characters: 768,
        target: 61,
      },
      {
        date: '12.06.18',
        _words: 0,
        'words other': 14,
        words: 14,
        _characters: 0,
        'characters other': 0,
        characters: 152,
        target: 14,
      },
      {
        date: '13.06.18',
        _words: 0,
        'words other': -18,
        words: -18,
        _characters: 0,
        'characters other': 0,
        characters: -159,
        target: -18,
      },
      {
        date: '15.06.18',
        _words: 0,
        'words other': 287,
        words: 287,
        _characters: 0,
        'characters other': 0,
        characters: 2210,
        target: 287,
      },
      {
        date: '16.06.18',
        _words: 0,
        'words other': 359,
        words: 359,
        _characters: 0,
        'characters other': 0,
        characters: 2822,
        target: 359,
      },
      {
        date: '18.06.18',
        _words: 0,
        'words other': 292,
        words: 292,
        _characters: 0,
        'characters other': 0,
        characters: 2225,
        target: 292,
      },
      {
        date: '19.06.18',
        _words: 0,
        'words other': -60,
        words: -60,
        _characters: 0,
        'characters other': 0,
        characters: -415,
        target: -60,
      },
      {
        date: '20.06.18',
        _words: 0,
        'words other': -21,
        words: -21,
        _characters: 0,
        'characters other': 0,
        characters: -157,
        target: -21,
      },
      {
        date: '21.06.18',
        _words: 0,
        'words other': -51,
        words: -51,
        _characters: 0,
        'characters other': 0,
        characters: -422,
        target: -51,
      },
      {
        date: '24.10.18',
        _words: 0,
        'words other': 372,
        words: 372,
        _characters: 0,
        'characters other': 0,
        characters: 2908,
        target: 372,
      },
      {
        date: '25.10.18',
        _words: 0,
        'words other': 26,
        words: 26,
        _characters: 0,
        'characters other': 0,
        characters: 191,
        target: 26,
      },
      {
        date: '26.10.18',
        _words: 0,
        'words other': 127,
        words: 127,
        _characters: 0,
        'characters other': 0,
        characters: 805,
        target: 127,
      },
      {
        date: '29.10.18',
        _words: 0,
        'words other': 471,
        words: 471,
        _characters: 0,
        'characters other': 0,
        characters: 3437,
        target: 471,
      },
      {
        date: '30.10.18',
        _words: 0,
        'words other': 160,
        words: 160,
        _characters: 0,
        'characters other': 0,
        characters: 1168,
        target: 160,
      },
      {
        date: '31.10.18',
        _words: 0,
        'words other': 124,
        words: 124,
        _characters: 0,
        'characters other': 0,
        characters: 1090,
        target: 124,
      },
      {
        date: '01.11.18',
        _words: 0,
        'words other': 136,
        words: 136,
        _characters: 0,
        'characters other': 0,
        characters: 1032,
        target: 136,
      },
      {
        date: '02.11.18',
        _words: 0,
        'words other': 388,
        words: 388,
        _characters: 0,
        'characters other': 0,
        characters: 3142,
        target: 388,
      },
      {
        date: '26.11.18',
        _words: 0,
        'words other': 32,
        words: 32,
        _characters: 26043,
        'characters other': 0,
        characters: 220,
        target: 32,
      },
      {
        date: '27.11.18',
        _words: 0,
        'words other': 225,
        words: 225,
        _characters: 26043,
        'characters other': 0,
        characters: 1531,
        target: 225,
      },
      {
        date: '28.11.18',
        _words: 0,
        'words other': -1,
        words: -1,
        _characters: 26043,
        'characters other': 0,
        characters: 2,
        target: -1,
      },
      {
        date: '29.11.18',
        _words: 0,
        'words other': 26,
        words: 26,
        _characters: 26043,
        'characters other': 0,
        characters: 186,
        target: 26,
      },
      {
        date: '30.11.18',
        _words: 0,
        'words other': 84,
        words: 84,
        _characters: 26043,
        'characters other': 0,
        characters: 668,
        target: 84,
      },
      {
        date: '03.12.18',
        _words: 0,
        'words other': 291,
        words: 291,
        _characters: 26043,
        'characters other': 0,
        characters: 2090,
        target: 291,
      },
      {
        date: '04.12.18',
        _words: 0,
        'words other': 73,
        words: 73,
        _characters: 0,
        'characters other': 0,
        characters: 567,
        target: 73,
      },
      {
        date: '05.12.18',
        _words: 0,
        'words other': 100,
        words: 100,
        _characters: 26043,
        'characters other': 0,
        characters: 762,
        target: 100,
      },
      {
        date: '06.12.18',
        _words: 0,
        'words other': 390,
        words: 390,
        _characters: 26043,
        'characters other': 0,
        characters: 2998,
        target: 390,
      },
      {
        date: '10.12.18',
        _words: 0,
        'words other': 316,
        words: 316,
        _characters: 26043,
        'characters other': 0,
        characters: 2566,
        target: 316,
      },
      {
        date: '11.12.18',
        _words: 0,
        'words other': 89,
        words: 89,
        _characters: 26043,
        'characters other': 0,
        characters: 642,
        target: 89,
      },
      {
        date: '12.12.18',
        _words: 0,
        'words other': 58,
        words: 58,
        _characters: 26043,
        'characters other': 0,
        characters: 456,
        target: 58,
      },
      {
        date: '14.12.18',
        _words: 0,
        'words other': 65,
        words: 65,
        _characters: 26043,
        'characters other': 0,
        characters: 344,
        target: 65,
      },
      {
        date: '17.12.18',
        _words: 0,
        'words other': -143,
        words: -143,
        _characters: 26043,
        'characters other': 0,
        characters: -1002,
        target: -143,
      },
      {
        date: '18.12.18',
        _words: 0,
        'words other': 3,
        words: 3,
        _characters: 26043,
        'characters other': 0,
        characters: 50,
        target: 3,
      },
      {
        date: '19.12.18',
        _words: 0,
        'words other': 35,
        words: 35,
        _characters: 26043,
        'characters other': 0,
        characters: 253,
        target: 35,
      },
      {
        date: '21.12.18',
        _words: 0,
        'words other': 9,
        words: 9,
        _characters: 0,
        'characters other': 0,
        characters: 56,
        target: 9,
      },
      {
        date: '07.01.19',
        _words: 0,
        'words other': 277,
        words: 277,
        _characters: 26043,
        'characters other': 0,
        characters: 1797,
        target: 277,
      },
      {
        date: '11.01.19',
        _words: 0,
        'words other': 178,
        words: 178,
        _characters: 26043,
        'characters other': 0,
        characters: 1314,
        target: 178,
      },
      {
        date: '14.01.19',
        _words: 0,
        'words other': 392,
        words: 392,
        _characters: 0,
        'characters other': 0,
        characters: 2675,
        target: 392,
      },
      {
        date: '15.01.19',
        _words: 0,
        'words other': 1,
        words: 1,
        _characters: 26043,
        'characters other': 0,
        characters: 9,
        target: 1,
      },
      {
        date: '16.01.19',
        _words: 0,
        'words other': 94,
        words: 94,
        _characters: 26043,
        'characters other': 0,
        characters: 740,
        target: 94,
      },
      {
        date: '17.01.19',
        _words: 0,
        'words other': 371,
        words: 371,
        _characters: 26043,
        'characters other': 0,
        characters: 2530,
        target: 371,
      },
      {
        date: '18.01.19',
        _words: 0,
        'words other': 412,
        words: 412,
        _characters: 0,
        'characters other': 0,
        characters: 2987,
        target: 412,
      },
      {
        date: '19.01.19',
        _words: 0,
        'words other': 139,
        words: 139,
        _characters: 0,
        'characters other': 0,
        characters: 1083,
        target: 139,
      },
      {
        date: '21.01.19',
        _words: 0,
        'words other': 5,
        words: 5,
        _characters: 26043,
        'characters other': 0,
        characters: -40,
        target: 5,
      },
      {
        date: '22.01.19',
        _words: 0,
        'words other': 20,
        words: 20,
        _characters: 26043,
        'characters other': 0,
        characters: 128,
        target: 20,
      },
      {
        date: '23.01.19',
        _words: 0,
        'words other': 8,
        words: 8,
        _characters: 0,
        'characters other': 0,
        characters: 50,
        target: 8,
      },
      {
        date: '24.01.19',
        _words: 0,
        'words other': 344,
        words: 344,
        _characters: 26043,
        'characters other': 0,
        characters: 2560,
        target: 344,
      },
      {
        date: '28.01.19',
        _words: 0,
        'words other': 1068,
        words: 1068,
        _characters: 26043,
        'characters other': 0,
        characters: 7803,
        target: 1068,
      },
      {
        date: '29.01.19',
        _words: 0,
        'words other': 1255,
        words: 1255,
        _characters: 26043,
        'characters other': 0,
        characters: 9641,
        target: 1255,
      },
      {
        date: '30.01.19',
        _words: 0,
        'words other': 39,
        words: 39,
        _characters: 26043,
        'characters other': 0,
        characters: 292,
        target: 39,
      },
      {
        date: '31.01.19',
        _words: 0,
        'words other': 917,
        words: 917,
        _characters: 26043,
        'characters other': 0,
        characters: 6641,
        target: 917,
      },
      {
        date: '01.02.19',
        _words: 0,
        'words other': 628,
        words: 628,
        _characters: 0,
        'characters other': 0,
        characters: 4862,
        target: 628,
      },
      {
        date: '02.02.19',
        _words: 0,
        'words other': 301,
        words: 301,
        _characters: 26043,
        'characters other': 0,
        characters: 2215,
        target: 301,
      },
      {
        date: '04.02.19',
        _words: 0,
        'words other': -22,
        words: -22,
        _characters: 0,
        'characters other': 0,
        characters: -170,
        target: -22,
      },
      {
        date: '05.02.19',
        _words: 0,
        'words other': 2,
        words: 2,
        _characters: 26043,
        'characters other': 0,
        characters: 18,
        target: 2,
      },
      {
        date: '06.02.19',
        _words: 0,
        'words other': 65,
        words: 65,
        _characters: 26043,
        'characters other': 0,
        characters: 557,
        target: 65,
      },
      {
        date: '07.02.19',
        _words: 0,
        'words other': 156,
        words: 156,
        _characters: 26043,
        'characters other': 0,
        characters: 1136,
        target: 156,
      },
      {
        date: '08.02.19',
        _words: 0,
        'words other': 94,
        words: 94,
        _characters: 0,
        'characters other': 0,
        characters: 704,
        target: 94,
      },
      {
        date: '11.02.19',
        _words: 0,
        'words other': -111,
        words: -111,
        _characters: 0,
        'characters other': 0,
        characters: -908,
        target: -111,
      },
      {
        date: '12.02.19',
        _words: 0,
        'words other': 1531,
        words: 1531,
        _characters: 26043,
        'characters other': 0,
        characters: 11406,
        target: 1531,
      },
      {
        date: '13.02.19',
        _words: 0,
        'words other': 0,
        words: 0,
        _characters: 0,
        'characters other': 0,
        characters: 5,
        target: 0,
      },
      {
        date: '15.02.19',
        _words: 0,
        'words other': 1,
        words: 1,
        _characters: 0,
        'characters other': 0,
        characters: 12,
        target: 1,
      },
      {
        date: '16.02.19',
        _words: 0,
        'words other': 397,
        words: 397,
        _characters: 0,
        'characters other': 0,
        characters: 2904,
        target: 397,
      },
      {
        date: '18.02.19',
        _words: 0,
        'words other': 52,
        words: 52,
        _characters: 26043,
        'characters other': 0,
        characters: 589,
        target: 52,
      },
      {
        date: '19.02.19',
        _words: 0,
        'words other': -7,
        words: -7,
        _characters: 0,
        'characters other': 0,
        characters: -86,
        target: -7,
      },
      {
        date: '20.02.19',
        _words: 0,
        'words other': 539,
        words: 539,
        _characters: 26043,
        'characters other': 0,
        characters: 4319,
        target: 539,
      },
      {
        date: '21.02.19',
        _words: 0,
        'words other': 196,
        words: 196,
        _characters: 0,
        'characters other': 0,
        characters: 1492,
        target: 196,
      },
      {
        date: '22.02.19',
        _words: 0,
        'words other': 268,
        words: 268,
        _characters: 26043,
        'characters other': 0,
        characters: 1978,
        target: 268,
      },
      {
        date: '23.02.19',
        _words: 0,
        'words other': 56,
        words: 56,
        _characters: 26043,
        'characters other': 0,
        characters: 478,
        target: 56,
      },
      {
        date: '25.02.19',
        _words: 0,
        'words other': 753,
        words: 753,
        _characters: 26043,
        'characters other': 0,
        characters: 5597,
        target: 753,
      },
      {
        date: '26.02.19',
        _words: 0,
        'words other': -5,
        words: -5,
        _characters: 26043,
        'characters other': 0,
        characters: -29,
        target: -5,
      },
      {
        date: '27.02.19',
        _words: 0,
        'words other': 511,
        words: 511,
        _characters: 0,
        'characters other': 0,
        characters: 3764,
        target: 511,
      },
      {
        date: '28.02.19',
        _words: 0,
        'words other': 183,
        words: 183,
        _characters: 26043,
        'characters other': 0,
        characters: 1555,
        target: 183,
      },
      {
        date: '02.03.19',
        _words: 0,
        'words other': 315,
        words: 315,
        _characters: 26043,
        'characters other': 0,
        characters: 2572,
        target: 315,
      },
      {
        date: '04.03.19',
        _words: 0,
        'words other': 6,
        words: 6,
        _characters: 26043,
        'characters other': 0,
        characters: 42,
        target: 6,
      },
      {
        date: '06.03.19',
        _words: 0,
        'words other': 38,
        words: 38,
        _characters: 26043,
        'characters other': 0,
        characters: 279,
        target: 38,
      },
    ]

    let accumulatedWords = 0
    let accumulatedCharacters = 0
    const msPerDay = 24 * 60 * 60 * 1000
    const dateOptions = {
      year: 'numeric',
      day: '2-digit',
      month: '2-digit',
    }

    // convert date into machine readable format
    this.progress.map(row => {
      const dateParts = row.date.split('.')
      const machineReadableDate = `20${dateParts[2]}-${dateParts[1]}-${
        dateParts[0]
      }`
      row.date = Date.parse(machineReadableDate)
      return true
    })

    // make sure dataset begins on a monday (needed for calendar)
    const firstDay = new Date(this.progress[0].date)
    // fill in dates for the days before the first entry
    let daysAfterMonday = firstDay.getDay() - 1
    if (daysAfterMonday === -1) daysAfterMonday = 6
    // console.log('adding days in front of set:', daysAfterMonday)
    if (daysAfterMonday > 0) {
      for (let i = 0; i < daysAfterMonday; i++) {
        const fluffData = {
          date: new Date(firstDay) - (i + 1) * msPerDay,
          type: 'fluff',
          words: 0,
          characters: 0,
        }
        this.progress = [fluffData, ...this.progress]
      }
    }

    // enrich data for each row
    this.progress.map((row, index) => {
      // create nice-looking date String
      row.dateLocale = new Date(row.date).toLocaleDateString(
        'de-DE',
        dateOptions
      )

      // calculate accumulated words and characters
      accumulatedWords += row.words
      row.totalWords = accumulatedWords
      accumulatedCharacters += row.characters
      row.totalCharacters = accumulatedCharacters

      if (index >= this.progress.length - 1) return true

      // Fill in blanks for days without data
      const rowDate = new Date(row.date)
      const nextRowDate = new Date(this.progress[index + 1].date)
      const daysToNextRow = (nextRowDate - rowDate) / msPerDay
      // console.log(row)
      // console.log(`Dates til next row: ${daysToNextRow}`)
      // if there's more than one day in between, add rows with stale data
      if (daysToNextRow > 1) {
        for (let i = 1; i < daysToNextRow; i++) {
          const newDate = rowDate.getTime() + msPerDay * i
          const fluffRow = {
            date: newDate,
            dateLocale: new Date(newDate).toLocaleDateString(
              'de-DE',
              dateOptions
            ),
            type: 'fluff',
            words: 0,
            totalWords: row.totalWords,
            characters: 0,
            totalCharacters: row.totalCharacters,
          }
          this.progress.push(fluffRow)
          // console.log(fluffRow)
        }
      }
      return true
    })

    // sort array by date again
    // let start = new Date().getTime()
    console.log('sorting progress by date again…')
    this.progress = _.sortBy(this.progress, [
      function(o) {
        return o.date
      },
    ])
    // var end = new Date().getTime()
    // console.log(`This took ${end - start} ms`)
  }
}

export default new WordMetadata()
