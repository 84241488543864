import React, { Component } from 'react'
import { ResponsiveBar } from '@nivo/bar'

class nivoBarChart extends Component {
  // constructor(props) {
  //   super(props)
  //   this.state = {
  //     // data: this.props.data,
  //     keys: this.props.keys,
  //     colorByEbene: this.props.colorByEbene,
  //   }
  // }

  // NOT NECESSARY
  // Calling a State change from the parent
  // changeData(newData) {
  //   console.log('ABOUT TO CHANGE DATA!!!')
  //   this.setState({
  //     data: newData,
  //   })
  // }

  colorByEbene(data, props) {
    if (props.colorByEbene) {
      // console.log(data, props)
      switch (data.id) {
        case 'eu':
          return '#2c7bb6'
        case 'national':
          return '#abd9e9'
        case 'local':
          return '#fdae61'
        default:
          return 'silver'
      }
    } else {
      return null
    }
  }

  //https://github.com/plouc/nivo/blob/master/packages/core/src/theming/defaultTheme.js
  theme = {
    axis: {
      legend: {
        text: {
          fontFamily: 'IBM Plex Sans',
          fontWeight: 500,
          fontSize: 13,
        },
      },
    },
    grid: {
      line: {
        // stroke: 'pink',
        // strokeWidth: 2,
        // strokeDasharray: '4 4',
      },
    },
  }

  render() {
    return (
      <div
        style={{
          width: '100%',
          height: '230px',
        }}
        className="nivochart"
      >
        <ResponsiveBar
          data={this.props.data}
          keys={this.props.keys || undefined}
          // indexBy="city"
          margin={{
            top: 20,
            right: 120,
            bottom: 50,
            left: 60,
          }}
          innerPadding={0}
          colors="nivo"
          colorBy={
            this.props.colorByEbene
              ? d => this.colorByEbene(d, this.props)
              : undefined
          }
          defs={[
            {
              id: 'dots',
              type: 'patternDots',
              background: 'inherit',
              color: '#38bcb2',
              size: 4,
              padding: 1,
              stagger: true,
            },
          ]}
          fill={[
            {
              match: {
                id: 'national',
              },
              id: 'dots',
            },
          ]}
          borderColor="inherit:darker(2.1)"
          theme={this.theme}
          // axisTop=null
          // axisRight=null
          scales={[{ id: 'y', axis: 'y', domain: [0, 100] }]}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Stadt',
            legendPosition: 'middle',
            legendOffset: 40,
          }}
          axisLeft={{
            // tickValues: [0, 20, 40, 60, 80, 100, 120, 140, 160],
            domain: [0, 300],
            min: 0,
            max: 300,
            minValue: 0,
            maxValue: 300,
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Verbindungen',
            legendPosition: 'middle',
            legendOffset: -50,
          }}
          legends={[
            {
              dataFrom: 'keys',
              anchor: 'bottom-right',
              direction: 'column',
              justify: false,
              translateX: 120,
              translateY: 0,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: 'left-to-right',
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
        />
      </div>
    )
  }
}

export default nivoBarChart
